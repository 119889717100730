<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.sys.menu.title.editHeadTitle') : $t('cip.plat.sys.menu.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>


let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {add, getDetail} from "@/api/system/params";
import ServerNameEnum from "@/util/ServerNameEnum";
import {mapGetters} from "vuex";

export default {
  name: "add",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
        filePath: '',
        paramType: '',
        paramValue: '',
        textareaRich: '',
        textarea: '',
      },
    }
  },
  computed: {
    ...mapGetters(['language']),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            // btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          // btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          console.log(language, "11111111")
          if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 100) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength10Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
      return [
        {
          label: this.$t('cip.plat.sys.params.field.paramName'),
          prop: 'paramName',
          labelWidth: 130,
          span: 8,
          isGlobal: true, //是否支持国际化
          maxlength: 50,
          placeholder: this.$t(`cip.plat.sys.params.field.paramName`),
          read: !['add', 'edit'].includes(this.type),
          rules: [{required: true, validator: validatePass, trigger: ['blur', 'change']}]
        },

        {
          label: this.$t('cip.plat.sys.params.field.paramType'),
          prop: 'paramType',
          labelWidth: 100,
          span: 8,
          type: 'select',
          dataType: "string",
          disabled: !['edit', 'add'].includes(this.type),
          dicUrl: `${baseUrl}system_type_param`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.params.field.paramType'),
              trigger: "blur"
            },
          ],
          // value:'数字',
          control: (val) => {

            if (val === 'file' || val === 'logo') {
              return {
                filePath: {
                  display: true,
                },
                paramValue: {
                  display: false

                },
                textareaRich: {
                  display: false
                },
                textarea: {
                  display: false
                },
              }
            } else if (val === 'textarea_rich') {
              return {
                textareaRich: {
                  display: true
                },
                paramValue: {
                  display: false
                },
                filePath: {
                  display: false
                },
                textarea: {
                  display: true
                },
              }
            } else {
              return {
                filePath: {
                  display: false
                },
                paramValue: {
                  display: true
                },
                textareaRich: {
                  display: false
                },
                textarea: {
                  display: false
                },

              }
            }
          },
        },


        {
          label: this.$t('cip.plat.sys.params.field.textareaRich'),
          prop: "textareaRich",
          type: 'content',
          labelWidth: 130,
          component: 'AvueUeditor',
          disabled: !['edit', 'add'].includes(this.type),
          display: false,
          options: {
            action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
            props: {
              res: "data",
              url: "link",
            }
          },
          minRows: 6,
        },


        {
          label: this.$t('cip.plat.sys.params.field.textarea'),
          prop: "textarea",
          type: 'content',
          labelWidth: 130,
          component: 'AvueUeditor',
          disabled: !['edit', 'add'].includes(this.type),
          display: false,
          options: {
            action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
            props: {
              res: "data",
              url: "link",
            }
          },
          minRows: 6,
        },


        {
          label: this.$t('cip.plat.sys.params.field.paramValue'),
          prop: 'paramValue',
          labelWidth: 130,
          span: 8,
          isGlobal: true, //是否支持国际化
          read: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.sys.params.field.paramValue`),
          display: true,
          row: true,
          rules: [{required: true, validator: validatePass, trigger: ['blur', 'change']}]
        },

        {
          label: this.$t('cip.plat.sys.params.field.filePath'),
          prop: 'filePath',
          type: 'upload',
          labelWidth: 130,
          dataType: 'string',
          loadText: this.$t('cip.plat.sys.params.field.loadText'),
          propsHttp: {
            url: 'link',
            name: 'originalName',
            res: 'data'
          },
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: this.$t('cip.cmn.rule.photograph'),
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
          display: true,
          span: 15,
          readonly: !['edit', 'add'].includes(this.type),

        },
        // 日期 预留
        // {
        //     label: this.$t('sinoma.system.params.releaseTime'),
        //   prop: "releaseTime",
        //   labelWidth: 130,
        //   type: "date",
        //   span: 8,
        //   format: "yyyy-MM-dd hh:mm:ss",
        //   valueFormat: "yyyy-MM-dd hh:mm:ss",
        //   disabled: !['edit', 'add'].includes(this.type),
        //   display: true,
        //   row: true,
        //   rules: [{
        //     required: true,
        //     message: this.$t('cip.cmn.rule.inputWarning') + this.$t('sinoma.system.params.releaseTime'),
        //     trigger: "blur"
        //   }
        //   ]
        // },
        {
          label: this.$t('cip.plat.sys.params.field.remark'),
          labelWidth: 130,
          maxlength: 200,
          prop: 'remark',
          type: 'textarea',
          showWordLimit: true,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.sys.params.field.remark`),
          span: 16
        },
      ]
    }
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    htmlEncode(html) {
      //1.首先动态创建一个容器标签元素，如DIV
      let temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
      (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
      //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
      let output = temp.innerHTML;
      temp = null;
      return output;
    },
    htmlDecode(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      let temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
        if (data.paramType == "logo" || data.paramType == 'file') {
          console.log(data.paramValue)
          this.dataForm.filePath = JSON.parse(data.paramValue).zh;
        }
        if (data.paramType == 'textarea_rich') {
          this.dataForm.textareaRich = JSON.parse(data.paramValue).zh;
          this.dataForm.textarea = JSON.parse(data.paramValue).en;
        }
        // this.dataForm.textareaRich = this.htmlDecode(data.textareaRich)
      })

    },

    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {

        if (this.$refs.formLayout.dataForm.paramType == 'textarea_rich' ||
          this.$refs.formLayout.dataForm.paramType == 'file' ||
          this.$refs.formLayout.dataForm.paramType == 'logo') {

          this.$refs.formLayout.dataForm.paramValue = ''
        }
        if (valid) {
          this.$loading()
          if (this.$refs.formLayout.dataForm.paramType == 'file' || this.$refs.formLayout.dataForm.paramType == 'logo') {
            this.$refs.formLayout.dataForm.paramValue = ''
            let a = this.$refs.formLayout.dataForm.filePath
            this.$refs.formLayout.dataForm.paramValue = {
              "zh": a,
              "en": a
            }
            this.$refs.formLayout.dataForm.paramValue = JSON.stringify(this.$refs.formLayout.dataForm.paramValue)
          }
          if (this.$refs.formLayout.dataForm.paramType == 'textarea_rich') {
            this.$refs.formLayout.dataForm.paramValue = ''
            let a = this.htmlEncode(this.$refs.formLayout.dataForm.textareaRich)
            let b = this.htmlEncode(this.$refs.formLayout.dataForm.textarea)
            this.$refs.formLayout.dataForm.textareaRich = this.htmlEncode(this.$refs.formLayout.dataForm.textareaRich)
            this.$refs.formLayout.dataForm.textarea = this.htmlEncode(this.$refs.formLayout.dataForm.textarea)
            console.log(this.$refs.formLayout.dataForm.textareaRich)
            console.log(this.$refs.formLayout.dataForm.textarea)
            this.$refs.formLayout.dataForm.textareaRich = {
              "zh": a,
              "en": b
            }
            console.log(this.$refs.formLayout.dataForm.textareaRich)
            this.$refs.formLayout.dataForm.textareaRich = JSON.stringify(this.$refs.formLayout.dataForm.textareaRich)
            console.log(this.$refs.formLayout.dataForm.textareaRich)
          }
          add(
            this.$refs.formLayout.dataForm
          )
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.dataForm = data;
                this.initData();

                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
